<template>
  <div class="jobs-create">
    <el-form :model="form" label-position="top" :rules="rules" ref="form">
      <el-row :gutter="20">
        <el-col :span="17">
          <el-card>
            <el-form-item label="职位名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入职位名称"></el-input>
            </el-form-item>
            <el-form-item label="职位介绍" prop="content">
              <ckeditor :editor="editor" v-model="form.content" :config="editorConfig"></ckeditor>
            </el-form-item>
          </el-card>
        </el-col>
        <el-col :span="7">
          <el-card>
            <el-form-item label="招聘人数" prop="total">
              <el-input v-model="form.total" type="number"></el-input>
            </el-form-item>
            <el-form-item label="工作地点" prop="city">
              <el-select v-model="form.city">
                <el-option value="北京市"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="state">
              <el-radio-group v-model="form.state" size="mini">
                <el-radio :label="0">未发布</el-radio>
                <el-radio :label="1">已发布</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit" :loading="submitting">确定保存</el-button>
            </el-form-item>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<style lang="scss">
.el-form-item .el-form-item__content {
  line-height: 1;
}
.ck-editor__editable { min-height: 800px; }
</style>
<script>
import editor from '@ckeditor/ckeditor5-build-classic'
import { jobsEdit, jobsInfo } from '@/request/modules/jobs'
import { EDITOR_CONFIG } from '@/config/ckeditor'
export default {
  data () {
    return {
      editor,
      editorConfig: EDITOR_CONFIG,
      form: {
        id: this.$route.query.id,
        name: '',
        content: '',
        state: 0,
        total: 1,
        city: '北京市'
      },
      rules: {
        name: [
          { required: true, message: '请填写职位名称', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请填写职位介绍', trigger: 'blur' }
        ],
        state: [
          { required: true, message: '请选择发布状态', trigger: 'change' }
        ],
        total: [
          { required: true, message: '请填写招聘人数', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '请填写工作地点', trigger: 'blur' }
        ]
      },
      submitting: false
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      jobsInfo({ id: this.form.id }).then(resp => {
        console.log(resp)
        Object.keys(this.form).map(key => {
          this.form[key] = resp[key]
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitting = true
          jobsEdit(this.form).then(resp => {
            this.$message.success('保存成功!')
            this.submitting = false
            this.$router.push('/jobs')
          }).catch(() => {
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
